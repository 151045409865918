
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$FundingShield-primary: mat-palette($mat-indigo);
$FundingShield-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$FundingShield-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$FundingShield-theme: mat-light-theme($FundingShield-primary, $FundingShield-accent, $FundingShield-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($FundingShield-theme);

@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";

@import "../node_modules/ngx-toastr/toastr.css"