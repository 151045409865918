/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
html, body { height: 100%; }
body { margin: 0;font-family: 'Open Sans', sans-serif; font-size: 14px; color: #444;}
h1, h2, h3, h4, h5, h5, p, ul, li, a, .mat-card, .mat-form-field, .mat-radio-button, .mat-tab-group, .mat-tab-label-content,.mat-expansion-panel-header,
.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab, .mat-menu-item, .mat-select
{font-family: 'Open Sans', sans-serif !important;}
button:focus, a:focus, input:focus{outline: none;}
.mr0{ margin-right: 0 !important;}
input[type="text"]:not(.ag-input-field-input), input[type="email"], input[type="password"], input[type="tel"], input[type="number"],
input[type="url"] {
  height: 30px;
  border: solid 1px #ddd;
  padding: 0 10px;
}

input[type="email"]::placeholder, input[type="text"]::placeholder, input[type="password"]::placeholder {color: #444;} 
.mat-select{height: 30px; padding: 0 10px;border: solid 1px #ddd; line-height: 26px;}
.mat-form-field-infix{ padding: 0 !important;}
.box-shadow{-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15); -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15); box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);}
/*----- common style start --------*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {box-shadow: 0 0 0 30px white inset !important;}
.mat-tooltip {background-color:#2857a9; font-size: 16px !important; border: solid 1px #fff; padding: 2px 15px !important; font-size: 14px !important; border-radius: 0 !important;
 margin-bottom: 4px !important; overflow: inherit !important;}
 .mat-tooltip::before,
.mat-tooltip::after {content: "";position: absolute; border-left: 10px solid transparent;border-right: 10px solid transparent;top: 100%;left: 50%;margin-left: -10px;}
.mat-tooltip::before {border-top: solid 10px #fff;}
.mat-tooltip::after {border-top: solid 10px #2857a9;margin-top: -2px;z-index: 1;}
.fs-button{ height: 30px !important; border-radius: 0 !important; line-height: 10px !important; background: #2857a9 !important;
cursor: pointer !important; color: #fff !important;}
.fs-button:hover{ background:#039be5 !important;}


  /*----- common style end --------*/
/*------------- style for header menu ---------*/
.mat-menu-panel{ margin-top: 6px; min-width: 160px !important;}
.mat-menu-item{ font-weight: 600; height: 40px !important;}
button.mat-menu-item:hover{ background: #2857a9 !important; color: #fff;}
/*---------- style for front page -----------*/
.front-page .mat-card-header-text{ width: 100%;padding: 5px 14px;}
.front-page .mat-form-field-underline{ background-color: #e5e5e5 !important; display: block;}
.front-page .mat-form-field-wrapper{font-family: 'Open Sans', sans-serif; font-size: 16px;}
.front-page .mat-input-element{ height: 30px; border: none; padding: 0;}

.front-page{ width: 100%; height: 100%; position: absolute; z-index: 999; display: table;}
.front-page .table-cell{ display: table-cell; width: 100%; height: 100%; vertical-align: middle;}
.front-page .front-mat-card{ margin: auto; max-width: 600px; width: 100%; padding: 0;
    -webkit-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.25);
}
.front-page .mat-card-header{ border-bottom: solid 1px #e5e5e5;}
.front-page .mat-card-header h2{ float: left; color: #2857a9; font-size: 26px; font-weight: 600; line-height: 40px; margin-bottom: 0;}
.front-page .mat-card-header span{ float:right; margin-top: 4px;}
.front-page .frontpage-form{ padding: 20px 30px 30px 30px;}
.frontpage-form p{ font-size: 16px;}
.frontpage-form .mat-form-field{ width: 100%;}
.frontpage-form .frontpage-btn-bg{ width: 100%; text-align: center; margin-top: 20px; display: inline-block;}
.front-page .frontpage-btn{ box-shadow: none; background: #2857a9; text-align: center; color: #fff; border-radius: 0; font-size: 24px; font-weight: 300; padding-bottom: 5px;
    min-width: 120px;}
.front-page .frontpage-btn:hover{ background: #039be5; text-decoration: none;}
.forgot-user-password{ width: 100%; margin-top: 20px; font-size: 16px; display: inline-block;}
.forgot-user-password a:first-child{ float: left;}
.forgot-user-password a:last-child{ float: right;}
.forgot-user-password a{color: #888888;}
.forgot-user-password a:hover{color: #2857a9;}
.backbutton-container > :first-child{ float: left;}
.backbutton-container > :last-child{ float: right;}
.show-password{position: absolute;right: 0;top: 0;cursor: pointer;}

/*---------- style for order page filter --------*/
  .mat-form-field-underline{ display: none;}
  .field-bg, .field-bg-filter{ float: left; margin-right: 30px;}
  .field-bg label, .field-bg-filter label{ margin-right: 15px; line-height: 30px; margin-bottom: 0;}
  .field-bg  .mat-form-field-wrapper, .field-bg-filter .mat-form-field-wrapper{ padding-bottom: 0 !important;}
  .field-bg .mat-form-field-infix, .field-bg-filter .mat-form-field-infix{ border-top:0; }
  .field-bg .mat-select-trigger, .field-bg-filter .mat-select-trigger{ height: 16px;}
  #toast-container > div {opacity:1 !important;}
  #toast-container > .toast-error {
    /*background-image: none;*/
    background-color: #fb3232;
    /*color: red;*/
  }
/*--------- style for create transaction --------*/
.transaction-left-top{ position: absolute; height: 130px; z-index: 2; width: 25%; padding-left: 15px;}
.custom-large-btn{ background: #2857a9 !important; height: 34px; border: solid 1px #e5eeff !important; line-height: 33px; color: #fff; width: 100%; border-radius: 0 !important;
    margin-bottom: 10px !important; font-style: italic;-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.31) !important; -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.31) !important;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.31) !important;}
.custom-large-btn span span{ font-size: 18px; font-style: normal; display: inline-block; margin-top: -7px;}
.custom-large-btn:hover{background: #039be5 !important;}
.submit-order-btn{position: absolute;width:calc(25% - 10px);top: 577px; padding: 0 15px 0 30px;z-index: 2;}
.submit-order{font-style: normal; font-size: 16px; margin-bottom: 0;}
.rush-orderd{ background: #fc3232 !important;}
.create-transaction{ height:calc(100vh - 82px); background: #fff; padding-bottom: 20px;padding-top: 20px;}
.left-heading-button{ height: 34px; padding:0 15px; font-size: 16px; font-weight: 600; background: #039be5; margin-bottom: 15px;
color: #fff; line-height: 32px;}
.left-menu-bg{ border: solid 1px #e5e5e5; float: left; width: 100%;}
.left-menu-bg ul{ margin: 0; padding: 0; border: solid 1px #e5e5e5; float: left; width: 100%; border-bottom: none;}
.left-menu-bg ul li{ list-style: none; margin: 0; width: 100%; float: left; width: 100%;}
.left-menu-bg ul li a{ width: 100%; padding: 6px 15px; float: left; border-bottom: solid 1px #e5e5e5; font-size: 16px; color: #505050;}
.left-menu-bg ul li a:hover, .left-menu-bg ul li a.active{ text-decoration: none; background: #2857a9; color: #fff;}
.list-container{ padding: 0 15px 15px 15px; float: left; width: 100%;}
.status-circle{ width: 24px; height: 24px; background: #d7d7d7; float:right; border-radius: 100%;}
.approved{ background: #81ca4d;}
.rejected{ background: #fb3232;}
.mat-tab-label.approved-status::after{ background: #81ca4d !important;}
.mat-tab-label.rejected-status::after{ background: #fb3232 !important;}
.legend{ float: right; margin-top: 4px; margin-right:0;}
.legend span{ margin: 0 4px; float: left; cursor: pointer;}
.create-transaction-scroll { /*height: calc(100vh - 210px);*/
  height: calc(100vh - 156px);
  overflow: auto;
  padding-top: 20px;
}
.create-transaction-scroll-lender{ height: calc(100vh - 265px); overflow: auto;}
.lender-checkbox{ margin-top: 5px;}
.create-transaction-scroll .search-bg{ width: 100%; clear: both; float: left; padding-bottom: 10px;}
.checkbox-filed .mat-checkbox-layout{ width: auto !important; padding: 0; margin: 0;}
.checkbox-filed .mat-checkbox-inner-container{ height: 30px; width: 30px;}
.checkbox-filed  .mat-checkbox-input{ width: 30px !important; height: 30px !important; left: 0; padding: 0 !important;}
.checkbox-filed .mat-checkbox-frame{ border: solid 1px #ddd !important;}
.checkbox-filed .mat-checkbox-background{ background: #fff !important; border: solid 1px #ddd;}
.checkbox-filed .mat-checkbox-checkmark-path{stroke:#2857a9 !important}
.required-field:after{color: red; content: "*";}
.fa-radio .mat-radio-outer-circle{ width: 26px; height: 26px; border: solid 1px #bfbfbf;}
.fa-radio .mat-radio-inner-circle{ height: 26px; width: 26px; background: #2857a9 !important;}
.fa-radio .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{ border: solid 1px #bfbfbf;}
.fa-radio .mat-radio-label{ width:auto; padding: 0; margin-bottom: 0;}
.fa-radio .mat-radio-container{ height: 26px; width: 26px;}
.tab-top-btn{ margin-top: -10px; text-align: right; padding-bottom: 10px;}
.tab-top-btn .fs-button{ min-width: 100px; margin-left: 20px; border: none;}
.tab-top-btn .fs-button:focus{outline: none; border: none;}
.tab-top-btn .cancel-btn{ background: #fc3232 !important;}
.tab-top-btn .cancel-btn:hover{background:#ff6b6b !important;}
.tab-top-btn .update-btn{background: #6cca5c !important;}
.tab-top-btn .update-btn:hover{background: #87e877 !important;}
/*----- style for right section ---------*/
.order-detail-right{ height: 100%;height: calc(100vh - 123px); position: relative; /*padding-bottom: 42px;*/}
.order-detail-right h3{ width: 100%; margin: 0; background: #2857a9; padding: 6px 15px; font-weight: 600; color: #fff;font-size: 16px;}
.order-detail-right .bottom-menu-bg{ position: absolute; width: 100%; min-height: 42px; bottom: 0; padding-left: calc(25% + 30px)}
.order-detail-right .bottom-menu-bg .button-bg{ float: right; width: 100%; border: solid 1px #ddd; padding: 6px; text-align: right;}
.order-detail-right .bottom-menu-bg .button-bg .fs-button{ margin: 0 5px;}
.order-detail-inner-container{height: 100%;}
.order-detail-inner-container .heading{ background: #039be5; color: #fff; font-size: 16px; padding:3px 15px; font-weight: 600;width: 100%;}
.box-border{ border: solid 1px #ddd;}
.box-border .mat-form-field-outline{ opacity: 0 !important; border: 0 !important;}
.box-border .mat-form-field-outline-end, .box-border .mat-form-field-outline-start{ border-width: 0 !important;min-width: inherit !important;}
.box-border .mat-form-field-flex{ padding: 0 !important;}
.box-border .mat-form-field-infix, .box-border .mat-form-field-wrapper{ padding: 0 !important; margin: 0 !important; border: 0 !important; width: 100%;}
.box-border .mat-form-field{ width: 100%; margin: 10px 0;}
.order-detail-right .checkbox-filed.client-checkbox{ margin-top: 8px;}
.order-detail-right .mat-form-field-subscript-wrapper{padding: 2px 0 0 200px !important; top: 25px; margin: 0;}
.loanInfoDateInput .mat-form-field-subscript-wrapper {
  padding: 2px 0 0 0px !important;
  top: 25px;
  margin: 0;
}
.brwrError .mat-form-field-subscript-wrapper {
  padding: 2px 0 0 0px !important;
  top: 25px;
  margin: 0;
}
  /*------- style for lender info start------*/
  .order-detail-inner-container .heading-filter {
  position: absolute;
  right: 15px;
  top: 2px;
}
.available-result{ height: calc(100vh - 272px); border:solid 1px #ddd; position: relative; padding-top: 30px;}
.available-result .heading input[type="text"]{width: 240px;height: 24px;outline: none;border: solid 1px #ddd;padding: 0 10px; font-size: 14px;}
.available-result .heading{ position: absolute; top: 0; left: 0;}
.page-inner-section{ padding: 15px; float: left; width: 100%;}
.field-bg label{ margin-right:30px;}
.field-bg .mat-form-field{ margin-right: 15px; width: 285px;}
.result-list{ height: 100%; overflow: auto;}
.result-list ul { margin: 0; padding: 0;}
.result-list ul li{ list-style: none; border-bottom: solid 1px #ddd; padding: 5px 15px;}
.result-list ul li:hover{ background: #ececec;}
.address{ position: relative;}
.address-container{ padding: 10px 15px;}
.address-container p{ font-weight: 600; margin-bottom: 0;}
.address-container span{ display: block; clear: both;}
.contact-information{padding: 15px;}
.alternate-contact{ margin-top: 30px;}
.contact-information label{ width: 200px; padding-right: 15px;}
.contact-information input:not(.FSchkboxCustom), .contact-information .mat-select {
  width: calc(100% - 200px) !important
}
.contact-information .mat-select{ border: solid 1px #ddd;line-height: 26px;}
.contact-information .field-bg label{ margin-right: 0; }
/*------- style for lender info end ---------*/

/*-------- style for borrower info --------*/
.additional-borrowers{ padding: 20px 0;font-size: 16px; font-weight: 600;}
.additional-borrowers .mat-checkbox{ margin-left: 20px;}
/*-------- style for loan info --------*/
.loan-info-second{ margin: 5px 0 8px 0;}
.loan-info-second .mat-checkbox{ margin-left: 10px;}
.radio-custom-label{ width: 40%; float: left; padding-right: 15px;}
.radio-btn-field .mat-radio-button{ margin-right: 20px;}
/*------ style for wire-info ---------*/
.wire-info-top{ margin-bottom: 10px;}
.wire-info-top p{ float: left; padding: 0; margin: 5px 10px 0 0}
.wire-info .radio-btn-field{ margin-top: 5px;}
.wire-info .radio-custom-label{width: 200px;padding-right: 15px;}
.contact-info .closing-agent{ margin-top: 20px; padding: 0;}
/*---------- style for closing agent -------*/
.closing-agent-border { /*height: calc(100vh - 222px);*/
  height: calc(100vh - 160px);
  overflow: auto;
}
.closing-agent-border .top-bar{ padding-top: 20px;}
.closing-agent-select label{ width: auto;}
.closing-agent-select .mat-select{ width: 30% !important;}
.closing-agent{ padding: 7px 0;}
.closing-agent p{ min-width: 40%; padding-right: 15px; margin-bottom: 0; display: inline-block;}
.closing-agent-grid-bg{height: calc(100vh - 400px); margin-top: 20px; background: #ff0000;}
.client-closing-agent-grid-bg { height: calc(100vh - 448px);}
/*--------------style for Underwriter Info ---------*/
.title-insurance { /*height: calc(100vh - 261px);*/
  height: calc(100vh - 215px);
}
.file-row{ padding: 10px 0;}
.order-detail-right .file-container .mat-form-field-subscript-wrapper{ padding:2px 0 0 0 !important;margin: 0;
  top: 29px;}
.fs-filetype label{ margin-top: 5px;margin-bottom: 0; width: 32%;}
.file-container{width: 68%; float: right;}
.fs-filetype .mat-form-field{ margin: 0; float: left;}
.fs-filetype ngx-mat-file-input{ border: solid 1px #ddd; height: 30px; width: 100%;}
.fs-filetype input[type="file"]{ height: 30px; width: 100%;}
.fs-filetype .filename{height: 28px;width: 100%;padding-left: 110px;padding-top: 5px;}
.fs-filetype .file-btn {
  position: absolute;
  /*left: 2px;
  top: 3px;
  height: 27px;*/
  width: 100px;
  background: #039be5;
  color: #fff;
  text-align: center;

  cursor: pointer;
  left: 0;
  top: 0;
  height: 30px;
  line-height: 28px;
}
.fs-filetype .mat-form-field-flex{ margin: 0 !important;}
.fs-filetype .mat-select-arrow-wrapper {transform: translateY(0) !important;}
.upload-doc-btn button{ margin-left:32% ; color: #fff; min-width: 100px;}
.uploaded-document{ padding: 0;
                    /*height: calc(100vh - 262px);*/

}
.uploaded-document .ag-root{ border: none !important;}
.ag-theme-balham .ag-status-bar{ border-bottom: 0;}

/*-------- style fpr popup ----------*/
.cdk-overlay-dark-backdrop{background: rgba(0, 0, 0, 0.60) !important;}
.mat-dialog-container{ border-radius: 0 !important; padding: 0 !important; overflow: hidden !important; position: relative;}
.mat-dialog-container h4{ font-size: 16px; font-weight:600; color: #2857a9;}
.mat-dialog-title{ background:#039be5; color: #fff; padding: 3px 15px 5px 15px; font-weight: 600;}
.mat-dialog-content{ margin: 0 !important; padding-bottom: 20px !important;}
.popup-close-btn{ background:#fff !important;color: #ff0000;position: absolute !important; right: 8px; top:8px;padding: 0 !important;min-width: initial !important; height: 24px;
  width: 26px; border-radius: 0 !important; line-height: 20px !important;}
  .confirm-option{ margin:0 0 0 15px !important;}
.mat-dialog-container .large-font{ font-size: 18px; margin-bottom: 25px;}
.mat-dialog-container .large-font strong{ color: #039be5;}
.mat-dialog-container label{ width: 135px; float: left; margin: 0; line-height: 14px; padding-top: 7px; height: 29px;}
.mat-dialog-container input, .mat-dialog-container .mat-select{ width:calc(100% - 135px);}
.mat-dialog-container .col-lg-6, .mat-dialog-container .col-lg-12{ padding-top:7px;padding-bottom:7px;}
.popup-add-btn{ padding-left: 135px;}
.popup-add-btn button{ width: 100%;}
.mat-typography h4{ margin-bottom: 10px;}
.bank-information{ margin-top: 20px !important;}
.popup-large-font{ font-size: 16px;}
 .mat-dialog-container .add-new-parent-popup label{ width: 175px !important;}
 .mat-dialog-container  .add-new-parent-popup input,  .add-new-parent-popup .mat-dialog-container .mat-select{width: calc(100% - 176px);}
 .add-new-parent-popup .radio-title{ line-height: 20px;}
 .add-new-parent-popup .popup-add-btn{ padding-left: 175px;}

 /*Commented below one line only because of issue 1351*/
.mat-ripple-element {
  opacity: 0 !important;
  display: none !important;
  
}
/*
.mat-radio-button .mat-radio-ripple:focus, .mat-radio-ripple:hover {
  border: 2px solid red !important;
}
.fa-radio .mat-radio-container {
  border: none;
}
.fa-radio .mat-radio-container:focus {
  border: 2px solid red !important;
}

  .fa-radio:focus {
    border: 2px solid red !important;
  }*/

/*---------- style for verticle tab ------*/
.verticle-tab .mat-tab-group {
  height: 100%;
}
.verticle-tab .mat-tab-header{ position: absolute !important; width: calc(25% - 8px); display: block; display: block !important; z-index: 1; border: solid 1px #ddd;
top: 140px; margin-top: -13px; padding: 15px;}
.with-submit .mat-tab-header{ padding-bottom: 50px !important;}
.with-pdf .mat-tab-header {
  margin-top: 28px !important;
}
.verticle-tab .mat-tab-body-wrapper{margin-left: calc(25% + 30px) !important;width: calc(100% - 25% - 30px) !important; border: solid 1px #ddd;}
.verticle-tab .mat-tab-labels{ display: block !important; width: 100%; justify-content:inherit !important;}
.verticle-tab .mat-tab-labels .mat-tab-label{ width: 100%; float: left; text-align: left; opacity: 1; height: 36px; border: solid 1px #e5e5e5; line-height: 28px !important;
  justify-content: left !important; padding: 0 10px; border-top: none;}
  .verticle-tab .mat-tab-labels .mat-tab-label:first-child{ border-top: solid 1px #ddd;}
  .verticle-tab .mat-tab-body-wrapper{ width: 100%; height: 100%;}
  .verticle-tab .mat-tab-body-content > div{ width: 100%;}
  .verticle-tab .mat-tab-label-content{ font-size: 16px;}
  .verticle-tab .mat-ink-bar{ display: none;}
  .verticle-tab .mat-tab-label.mat-tab-label-active, .mat-tab-labels .mat-tab-label:hover{ background: #2857a9; color: #fff;}
  .verticle-tab .mat-tab-label:after {display: block;content: ''; position: absolute; right: 10px; width: 20px; height: 20px; background: #d7d7d7; border-radius: 100%;
height: 24px; width: 24px;}
.verticle-tab .mat-tab-header-pagination{display: none !important;}
 /*----------close style for verticle tab ------*/

 /*--------- style start fpr horizontal tab ------*/
 .horizontal-tab .mat-tab-label{ height:38px; opacity: 1; min-width: 250px;}
 .horizontal-tab .mat-tab-label-active{ color: #039be5;}
 .horizontal-tab .mat-ink-bar{ background: #2857a9;}
 .horizontal-tab .mat-tab-body-content {max-height: calc(100vh - 159px) !important; padding: 15px; overflow: inherit;}
 .horizontal-tab .mat-tab-body-content label{ min-width: 140px; padding-right: 10px; float: left;padding-top:3px; margin-bottom:5px;}
 .horizontal-tab .mat-tab-body-content .col-lg-12{ padding-top: 6px; padding-bottom: 6px;}
 .horizontal-tab .mat-tab-body-content input{ width:calc(100% - 140px); float: left;}
 .horizontal-tab .show-password{ position: relative;float: left; margin-left: 0; background: #e2e2e2; height: 30px; margin-top: -1px;
  width: 30px; text-align: center; line-height: 30px; margin-left: -30px;}
 .horizontal-tab .mat-tab-body-content .profiles-right-btn{ padding-left: 140px;}

.horizontal-tab .mat-tab-body-content .fs-button {
  min-width: 320px;
}
.profiles-right-btn .fs-button {width: 100%; min-width: inherit !important;}
 .horizontal-tab .fs-filetype label{ width:auto; float: left;}
 .horizontal-tab .file-container{ width: 320px; float: left;}
 .horizontal-tab .mat-form-field-wrapper{padding: 0 !important;margin: 0 !important;border: 0 !important; width: 100%;}
 .horizontal-tab .mat-form-field-outline {opacity: 0 !important; border: 0 !important;}
 .mat-form-field-outline-start, .mat-form-field-outline-gap{ border: 0; display: none !important;}
 .horizontal-tab .mat-form-field-infix{border: 0; padding: 0 !important;}
 .horizontal-tab .mat-form-field-flex{ padding: 0 !important}
 .horizontal-tab .fs-filetype .mat-form-field{ width: 100%;}

.tree-view-profile ngx-treeview { width:100%}
.horizontal-tab .mat-tab-body-content .tree-view-profile input, .horizontal-tab .mat-tab-body-content .tree-view-profile label {
  width: auto !important;
  min-width: auto !important;
}
 /*--------- style close fpr horizontal tab ------*/

 /*----------- style for dashboard ----------*/
 .dashboard-top-bar{ background: #2857a9; padding: 5px 15px; border: solid 1px #fff;-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.32);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.32); box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.32); margin-top: 15px; float: left; width: 100%;}
  .dashboard-topbar-left{ font-size: 18px; color: #fff; float: left;}
  .dashboard-topbar-right{ float:right;}
  .dashboard-topbar-right .mat-select{ background: #fff;}
  .dashboard-topbar-right .field-bg{ margin-right: 20px; width: 210px;}
  .dashboard-topbar-right .field-bg:last-child{ margin-right: 0;}
  .average-order-choose-date{ width: 100%;}
  .average-order-choose-date .mat-form-field-wrapper{padding-bottom: 0;}
  .average-order-choose-date .mat-form-field-infix { padding: 6px 0 !important; border: 0; line-height: 20px;}
  .average-order-choose-date label span{ color: #444; line-height: 20px;}
  .average-order-choose-date.mat-form-field-should-float .mat-form-field-label{ display: none !important;}

.order-tile-row{ padding: 15px 0;}
.order-tiles{ width: 100%; background: #ddd; min-height: 30px;border: solid 1px #e3e3e3; color: #fff; font-size: 22px; min-height: 40px; position: relative;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15); box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
display: table;}
.order-tile-row .col-lg-2{flex: 0 0 20%; max-width: 20%;}
.order-tiles .tile-title{ padding: 0 100px 0 15px; height: 40px; line-height: 19px; display: table-cell; vertical-align:middle;}
.order-tiles .count{ position: absolute; right: 0; top: 0; width: 90px; text-align:center; height: 100%; font-size: 28px; font-weight:600;}
.total-order{ background: #039be5;}
.total-order .count{background: #33bcff}
.approve-order{background:#6cca5c;}
.approve-order .count{background: #87e877}
.rejected-order{background: #fc3232;}
.rejected-order .count{background: #ff6b6b}
.in-progress{ background: #899093;}
.in-progress .count{background: #a1abb0}
.rush-order{ background: #5d4a8e;}
.rush-order .count{background: #7762ac}
.dashboard-main-page{ height: calc(100vh - 226px); overflow: hidden; flex:inherit}
.dash-inner-container{ height: 50%;}
.dashboard-grid-colum, .dashboard-left-colum{ height: 100%; padding-bottom: 0;}
.dashboard-left-colum .dash-inner-container:first-child{ padding-bottom: 15px;}
.dashboard-left-colum .dash-inner-container:last-child{ padding-top: 15px;}
.dashboard-box-container{ border: solid 1px #ddd; height: 100%;}
.dashboard-box-container h3{ background: #2857a9; font-size: 20px; font-weight: 400; color: #fff; padding: 0 15px; height: 30px;
line-height: 28px; margin-bottom: 0;}
.pai-chart-bg{ height:calc(100% - 36px); overflow: hidden;}
.pai-chart-bg .ngx-charts-outer{ margin: auto;}
.dashboard-chart-status{width: calc(100% + 30px);clear: both;border-top: solid 1px #ddd;margin: 0 -15px;padding: 7px 15px; text-align: center;}
.dashboard-chart-status ul { padding: 0; margin: 0; display: inline; text-align: center;}
.dashboard-chart-status ul li{position: relative; list-style: none; display:inline-block; margin: 0 12px; padding: 0 0 0 16px;}
.dashboard-chart-status ul li::after{ display: block; content: ''; width: 12px; height: 12px; position: absolute; left: 0; top: 5px;
background: #ddd; border-radius: 100%;}
.dashboard-chart-status ul li:first-child{ color: #6cca5c; margin-left:0}/*1172 changes*/
.dashboard-chart-status ul li:first-child::after{ background: #6cca5c;}
.dashboard-chart-status ul li:nth-child(2){ color: #fc3232;}
.dashboard-chart-status ul li:nth-child(2)::after{ background: #fc3232;}
.dashboard-chart-status ul li:nth-child(3){ color: #889093;}
.dashboard-chart-status ul li:nth-child(3)::after{ background: #889093;}

.dashboard-details-container{height: calc(100% - 30px); padding:0 15px;}
.dash-container01 {
  padding:0 2px 0 15px;
}



.dashboard-details-container .status-of-order:first-child div {
  font-weight: 600;
}
.status-of-order{ display: flex; font-size: 15px; display: table-row; font-weight: 600;}
.status-of-order div{ padding: 4px 2px; text-align: center; display: table-cell; vertical-align: middle;}
.status-of-order div:first-child{ padding-left: 0; width: 100%; text-align: left; font-weight: normal; color: #444;}
.status-of-order div:nth-child(2){ color: #fb3232;}
.status-of-order div:nth-child(4){ color: #4abc38;}
.ag-grid-container{ padding: 0;}
.ag-grid-container ag-grid-angular{ width: 100%; height: 100%;}
.ag-grid-container .ag-theme-balham .ag-root{border: none;}
/*------ style for static page --------*/
.static-page{height:calc(100vh - 97px); overflow: auto; padding-top: 20px;}
.static-page h4{ font-size: 18px; font-weight: 600; color: #2857a9;}
/*--------- style for profile page -------*/
.profiles-bg{height:calc(100vh - 97px); overflow: auto; padding-top: 20px;}
.profile-row{ position: relative; height: 100%;}
.left-profile{ width:220px; height: 100%;position: absolute; left:15px; z-index: 1;}
.left-profile .image-container{ width: 100%; height: 200px; border: solid 1px #eaeaea; margin-bottom: 10px;}
.left-profile .image-container img{ width: 100%; height: 100%;}
.left-profile .name{ text-align: center; font-size: 16px;}
.left-profile .designation{ color: #039be5; text-align: center;}
.tab-container-bg{ width: 100%; height: 100%; padding-left: 265px;}
.right-profile{ height: 100%; width: 100%; border: solid 1px #eaeaea;}

/*-------- style for order details -----------*/
.order-tdetails{height:calc(100vh - 97px); overflow: auto; padding-top: 20px;}
.box-container-main{ width: 100%; border: solid 1px #e5e5e5; float: left;}
.box-container-main h3{background:#039be5; color: #fff; padding: 6px 15px; font-size: 16px; margin-bottom: 0; font-weight: 600;}
.box-container-main .box-container-body{ padding: 15px;}
.box-container-main .box-container-body .row > div{ padding-bottom: 7px;}
.box-container-main .box-container-body strong{ padding-right: 4px;}
.lender-company{ width: 178px; float: left;}
.green{color: #86b563; font-weight: 600;}
.box-container-main .message{padding-left: 176px;display: inherit;}
.wavs{margin-top: 25px;}
.wavs strong{ width: 200px; float: left; height: 100%;}
.wavs span{padding-left: 205px;display: inherit;}
.prewavs{margin-top: 25px;}
.prewavs strong{ width: 200px; float: left; height: 100%;}
.prewavs span{padding-left: 205px;display: inherit;}
.check-ofac{ margin-left: 10px; text-decoration: underline; font-weight: 600;}
/*--------- style for accordion ---------*/
.accordion{ margin-top: 25px; margin-bottom: 15px;}
.mat-accordion .mat-expansion-panel{ box-shadow: none !important;border-left: solid 1px #ddd;border-right: solid 1px #ddd;}
.box-container-main.accordion{ border-bottom: solid 1px #ddd !important;}
.accordion.box-border{border: none;}
.accordion .mat-expansion-panel{ border-radius: 0 !important; margin: 0;}
.accordion .mat-expansion-panel-header:focus,.accordion .mat-expansion-panel-header:hover{background: #5679b9 !important;}
.accordion .mat-expansion-panel-header{background: #5679b9; padding: 0 15px; height: 31px !important; max-height: 31px !important;border-bottom: solid 1px #769bdc;}
.accordion .mat-expanded .mat-expansion-panel-header{ background: #039be6 !important;}
.accordion .mat-expansion-panel-header-title{color: #fff;font-size: 16px;margin-bottom: 0; font-weight: 600;}
.accordion .mat-expansion-panel-body{ padding:10px 15px !important; position: relative;}
.accordion .mat-expansion-indicator::after {content: '+' !important; display: inline-block;padding: 3px; vertical-align: middle; width: 20px; height: 20px; color: #fff;
border: 0 !important; transform: rotate(0) !important;font-size: 24px; text-align: center;line-height: 8px; padding: 0;
margin-top:8px;
}
.accordion .mat-expanded .mat-expansion-indicator::after{content: '-' !important;line-height: 15px;
  font-size: 29px; margin-top:0;}
.accordion label{ margin-bottom: 0; line-height: 14px; padding-top: 6px;}
.accordion input{ margin: 0;}
.accordion-action-btn{ float: right; position: relative; z-index: 1; padding-bottom: 10px;}
.accordion-action-btn button{ margin-left: 15px; min-width: 80px;}
.green-btn{background: #86b563 !important;}
.green-btn:hover{ background: #63983c !important;}
.accordion .checkbox-filed{ padding: 7px 0;}
.accordion .checkbox-left-align label{ width:auto; padding-right: 15px;}
.accordion button{ min-width: 80px;}
/*------ cpl accordion -------*/
.cpl-date-box{position: absolute; right: 0;width: 100%; padding-left: 75px;}
.cpl-date-box button{right: 0; position: absolute;}
.cpl-date-box input{ width: calc(100% - 63px); float: left;}
.cpl-date label{ width:70px; float: left; z-index: 1; position: relative;}
.cpl-common input{ width: calc(100% - 82px);}
.cpl-state-validation label{ width: 154px;}
 .cpl-state-validation .mat-checkbox-checked.mat-accent .mat-checkbox-background{background: #6bc95c !important;} 
.cpl-state-validation .checkbox-filed .mat-checkbox-checkmark-path{stroke: #ffffff !important;}
.validate-error{ font-size:13px; color: #fb3232; position: absolute; left: 0; bottom: -13px;}
.home-borrower .borrower-form-row{ margin: 0 !important;}
/* -------- style for lender info ------*/
.lender-info-select{ margin-top: -30px;}
.lender-info-select .field-bg{ margin: 0; float: left;}
.lender-info-select label{float: left;width: 150px;margin-right: 0;}
.lender-info-select .mat-select {width: calc(100% - 150px);float: right;}
.lender-info-para p{ margin: 15px 0;}
.comomn-fromdesign label{ width: 150px;}
.comomn-fromdesign input, .comomn-fromdesign .mat-select{ float:right; width: calc(100% - 150px);}
/* -------- style for closing info ------*/
.closing-info-search{ margin-bottom: 15px;}
.closing-info-search label{ float: left; width: auto; padding-right: 15px;}
.closing-info-search input{ float: left; width: auto;}
.closing-info-bg{ width: 100%; min-height:200px; border: solid 1px #ddd;
position: relative;}
.closing-info-bg ag-grid-angular{ position: absolute; width: 100%; height: 100%;}
.closing-agent-info h4{ font-size: 16px; font-weight: 600; margin-top: 20px;}
.closing-info-bg .ag-root{ border: none;}
/* -------- style for underwriter info ------*/
.underwriter .field-bg{ margin: 0; float: left;}
.underwriter label{float: left;width: 150px;margin-right: 0;}
.underwriter .mat-select {width: calc(100% - 150px);float: right;}
.insurance-space{ margin-bottom: 15px; margin-top: 10px;}
.hr{ margin: 10px 0; font-size: 0; line-height: 0; height: 1px; background: #ddd;}
/*------------style for order details right side ------*/
.status-button{ width: 100%; background: #dfdfdf; color: #fff; padding: 0 15px;-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15); box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15); height: 31px; margin-top: 1px;}
.status-button .status{ padding-right:10px; float: left; line-height: 30px; font-size: 16px; font-weight: 600;}
.status-button .checkbox-filed{ display: inline-flex;}
.status-button .checkbox-filed .mat-checkbox{ margin-top: 3px;}
.status-button .checkbox-filed .mat-checkbox-inner-container{ height: 24px; width: 24px;}
.status-button label{ margin-bottom: 0; line-height: 30px; padding-left: 10px;}

.status-button.received{ background: #86b563;}
.button-container-double{ width: 100%; margin-bottom: 20px; float: left;}
.button-container-double button{ width: 45%;}
.button-container-double button:nth-child(2){ float: right;}
.button-container-double .spinner-grow{ position: absolute; right: 10px;}
.button-container-single{ margin-bottom: 20px;}
.button-container-single button{ width: 100%;}
.order-details-right .box-container-main{ margin-bottom: 20px;}
.order-details-right .dashboard-details-container{ padding: 0;}
.order-details-right .status-circle{ height: 20px; width: 20px; background: #dfdfdf; border: solid 1px #c4c4c4; display: inline-block; float: none; margin-top: 5px;
cursor: pointer;}
.order-details-right .status-circle:hover{ background: #2857a9;}
.order-details-right .status-of-order div:first-child{ line-height: 16px;}
.order-details-right .status-of-order div:nth-child(2), .order-details-right .status-of-order div:nth-child(4){ color: inherit;}
.order-details-right .dashboard-details-container .status-of-order:first-child { margin-bottom: 10px;}
.order-details-right .status-rejected{ border-radius: 0; border-color: #af1e1e; background: #ff0000;}
.order-details-right .status-rejected:hover{background: #ff4343;}
.order-details-right .status-approved{ border-radius: 0; border-color: #63983c; background: #86b563;}
.order-details-right .status-approved:hover{background: #63983c;}

.status-request{ border-radius: 0; border-color: rgb(235, 235, 65) !important; background: #FFFF00 !important;}
.status-request:hover{background: #ff4343;}

 .status-received{ border-radius: 0; border-color: #33bcff !important; background: #33bcff !important;}
 .status-received:hover{background: #33bcff;}


/*------ style for ngrx select box -----*/
.ngx-select-container {
  width: calc(100% - 135px);
  float: left;
}

  .ngx-select-container .ngx-select__selected {
    width: 100%;
    float: left;
  }

  .ngx-select-container input[type="text"] {
    width: 100%
  }

  .ngx-select-container .ngx-select__toggle {
    border-radius: 0;
    height: 30px !important;
    line-height:15px;
  }
  .ngx-select-container .ngx-select__toggle:hover {
    background:transparent !important;
  }

    .ngx-select-container .ngx-select__search{ width:100% !important;}
  .ngx-select-container .form-control:focus{ box-shadow:none !important; outline:0;}
.form-control:focus{ border-color:#ddd; border-radius:0;}

/*------ style start for mat table -------*/
.mat-table{ border:solid 1px #ddd; box-shadow:none !important;}
tr.mat-header-row{ height:36px !important;}
.mat-header-cell{ font-weight:600; font-size:14px !important;}
tr.mat-row{ height:40px !important;}
tr.mat-row .mat-cell{ font-weight:400;}
tr.mat-row .mat-cell .mat-radio-button{ margin-top:7px;}
  /*------ style start for mat table -------*/
  .order-details-right .view-document-list ul {
    margin: 0;
    padding: 0;
  }
.order-details-right .view-document-list ul li{ list-style: none; padding: 5px 0;}
.order-details-right .view-document-list .material-icons{ color: #fb3232; float: left; margin-right: 10px;}
.gaurdian-certi a{ color: #444; float: left; padding-right: 20px;}
.gaurdian-certi button{ width: 100%; margin-top: 10px;}
.gaurdian-certi .checkbox-filed{ margin-top: 10px;}
.transaction-Status .box-container-body{ display: inline-block; clear: both; width: 100%;}
.gaurdian-certi label{ margin-right: 15px !important;}
.transaction-Status .field-bg{ width: 100%; margin-right: 0;}
.transaction-Status .field-bg label{width: 70px;font-weight: 600; float: left;}
.transaction-Status .field-bg .mat-select, .transaction-Status .status-box .status-details{width: calc(100% - 100px); float: left;}
.transaction-Status .status-box{ margin-bottom: 15px; float: left; width: 100%;}
.transaction-Status .status-box .status{ width: 70px; font-weight: 600; margin-right: 30px; float: left;}
.view-notes .box-container-body{ padding: 0;}
.view-notes .notes-bg strong{ padding: 0 10px;}
.view-notes .notes-bg{ padding: 15px; border-bottom:none; margin-bottom: 0;}
.view-notes .notes-bg .notes-row{ display: table; width: 100%; padding: 2px 0;}
.view-notes .notes-bg:nth-child(odd){ background: #f4f4f4;}
.view-notes .notes-bg .notes-row div{ display: table-cell;}
.view-notes .notes-bg .notes-row div:first-child{ font-weight: 600; width: 90px; padding-right: 10px;}
.orders-page .ag-side-bar{ border: none; border-left: solid 1px #ddd !important;}
.notes-bg .add-notes{ padding: 0 15px 5px 15px;}
/*-------- style for notes-bg ------*/
.notes-bg{ width: 100%; margin-bottom: 15px; border: solid 1px #ddd;}
.notes-bg h4{width: 100%; margin: 0; background: #029be5; padding: 6px 15px; font-weight: 600; color: #fff; font-size: 16px;}
.notes-textarea{ padding: 15px 15px 10px 15px; width: 100%;}
.notes-textarea textarea{ height: 100px; width:100%; border: solid 1px #ddd; padding:5px 10px;}
.notes-textarea textarea:focus{outline: none;}
.notes-bg .notes-btn-bg{ padding: 5px 15px; background: #efefef; border-top: solid 1px #ddd;}
.notes-bg .notes-btn-bg button{ margin: 0 15px 0 0;}
/* ------ style start for client and user module -------*/
.client-user-button-top{ width: 100%; padding: 5px 0; float: left;}
.client-user-button-top button{ margin: 0 0 0 10px; float:right;}
.client-user-ag-grid{ height: calc(100vh - 137px); border: solid 1px #ddd; float: left; width: 100%;}
.client-user-ag-grid .ag-grid-container{ height: 100%;}
.client-user-ag-grid .ag-details-row{ padding: 10px;}
.client-user-ag-grid .ag-details-row .ag-details-grid{ border: solid 1px #d9dcde;}
.client-user-ag-grid .ag-center-cols-container{ width: 100% !important;}
/* ------ style start for client and user module -------*/
.loandetail-funding-date .mat-form-field-suffix {position: absolute;right: 0;top: 0 !important;z-index: 5;}
.loandetail-funding-date .mat-form-field-suffix button{ width: 40px !important; height: 30px !important; min-width: inherit;}
.loandetail-funding-date .mat-form-field-flex{ margin-top: 0 !important;}
/*------------ style for responsive design ----------*/
.dashboard-left-colum .dash-inner-container:first-child{ padding-bottom: 0;}
.flatpickr-input{ width: 100%; height: 20px !important;}
@media screen and (max-width: 1680px) {
  .available-result .heading input[type="text"]{ width: 130px;}
  .available-result .heading{ padding-right: 150px;}
}
@media screen and (max-width: 1439px) {
  .order-tiles{ min-height: inherit;}
  .order-tiles .tile-title{ font-size: 18px; height: 36px; padding:0 80px 0 10px;}
  .order-tiles .count{ width: 75px; font-size: 22px; line-height: 34px;}
  .dashboard-chart-status ul li{ margin-right: 0;}
  .field-bg, .field-bg-filter{ margin-right: 15px;}
  .order-detail-inner-container .heading{ height: 30px;}
  .underwriter-info .available-result .heading{ font-size: 12px !important; line-height: 22px;}
  .row.cpl .col-lg-3{ -webkit-box-flex: 0;flex: 0 0 50%; max-width: 50%;}
  .button-container-double button{ width: 48%; padding: 0 5px;}
  app-client-info .comomn-fromdesign .col-lg-4,  app-client-info .lender-info-select, .borrower-form-row .col-lg-4,
  app-property-info .comomn-fromdesign .col-lg-4, app-loan-details .comomn-fromdesign .col-lg-4,
  app-closing-agent-info .comomn-fromdesign .col-lg-4, app-underwriter .comomn-fromdesign .col-lg-4,
  app-wire-insurance .comomn-fromdesign .col-lg-4
  {flex: 0 0 50%;max-width: 50%;}
  .borrower-form-row .col-lg-4 strong{ display: block; padding-bottom: 10px;}
  .status-of-order div{padding: 2px 3px;}
}
@media screen and (max-width: 1279px) {
  .order-tile-row .col-lg-2{ padding-right: 0;}
  .order-tile-row .col-lg-2:last-child{ padding-right: 15px;}
  .order-tiles .count{ font-size: 18px; width: 70px;}
  .order-tiles .tile-title{ font-size:16px; padding-right: 70px;}
  .dashboard-chart-status{ padding: 7px 10px;}
  .dashboard-chart-status ul li{ margin-left: 10px; padding-left: 20px;}
  .orders-page{ padding-top: 10px !important;}
  .loan-search{ padding-bottom: 5px !important;}
  .loan-search .error{ bottom: 0 !important;}
  .closing-info-search label{ width: 100%; clear: both; padding-bottom: 10px;}
  .closing-info-search input{ width:calc(100% - 80px);}
  .order-filter-top{ float: left; padding:4px 15px !important;}
  .order-filter-top .field-bg-filter{ padding:5px 0;}
  .order-filter-top .field-bg-filter:nth-child(2), .order-filter-top .field-bg-filter:nth-child(4){ margin-right: 0;}
  .order-filter-top .field-bg-filter:nth-child(3){ margin-right: 15px !important;}
  .order-table-main .field-bg, .order-table-main .field-bg-filter{ width:calc(50% - 8px); float: left !important;}
  .order-filter-top label{ width: 140px;}
  .order-filter-top .mat-form-field{ width: calc(100% - 160px);}
  .order-table-main{height: calc(100vh - 156px) !important;}
  .order-table-main .grid-bg{ padding-top: 92px;height: calc(100% - 37px) !important;}
  .submit-order-btn{ width: 274px !important;}
  app-admin .order-tdetails .row > .col-lg-9{-webkit-box-flex: 0;flex: 0 0 66.666667%;max-width: 66.666667%;}
  app-admin .order-tdetails .row > .col-lg-3{-webkit-box-flex: 0; flex: 0 0 33.333333%;max-width: 33.333333%;}
}

@media screen and (min-width: 991px) and (max-width:1279px) {
  .contact-information label, .contact-information .file-container{ width: 100%; padding-right: 0;}
  .contact-information input, .contact-information .mat-select{ width: 100% !important;}
  .transaction-left-top{ width: 27% !important;}
  .verticle-tab .mat-tab-header{ width: calc(27% - 8px); padding: 10px;}
  .transaction-left-top .custom-large-btn{ padding: 0 10px;}
  .custom-large-btn span{ font-size: 14px !important;}
  .loan-info .mat-form-field-suffix{ top: 34px !important;}
  .loan-info .loan-info-second .checkbox-filed{ position: relative; padding-right:40px; }
  .loan-info .loan-info-second .mat-checkbox{ position: absolute; right: 0; top: 6px;}
  .loan-info .radio-custom-label{ width: 100%; padding-right: 0;}
  .file-row{width: 100%; display: inline-block; padding-top: 0;}
  .fs-filetype label{ margin-bottom: 5px;}
  .upload-doc-btn button{ margin-left: 0;}
  app-order-detail .col-lg-4, app-cpl .row.cpl .col-lg-3{-webkit-box-flex: 0;flex: 0 0 50%; max-width: 50%;}
  app-wavs .col-lg-6{-webkit-box-flex: 0;flex: 0 0 100%; max-width: 100%;}
  app-pre-wavs .col-lg-6{-webkit-box-flex: 0;flex: 0 0 100%; max-width: 100%;}
}

@media screen and (max-width: 1023px) {
  .dashboard-topbar-left{ font-size: 16px; line-height: 28px;}
  .dashboard-topbar-right .field-bg{ width: 145px;}
}

@media screen and (max-width: 991px) {
  .verticle-tab .mat-tab-body-wrapper{margin-left: calc(30% + 30px) !important;
    width: calc(100% - 30% - 30px) !important;}
    .order-detail-right .bottom-menu-bg{padding-left: calc(30% + 30px) !important}
.transaction-left-top{ width: 32% !important;}
.verticle-tab .mat-tab-header{ width: calc(32% - 5px); padding: 10px;}
.verticle-tab .mat-tab-label:after, .status-circle{ width: 20px; height: 20px;}
.left-heading-button{ font-size: 14px;}
.transaction-left-top .custom-large-btn{ padding: 0 5px;}
.transaction-left-top .custom-large-btn span{ font-size: 12px !important;}
.box-border .mat-form-field{ margin-top: 0;}
.available-result{ height: auto; max-height: 400px;}
.alternate-contact, .box-border{ margin-bottom: 15px;}
.closing-agent-border {height: calc(100vh - 200px);overflow: hidden;}
.closing-agent-border .closing-agent-border{height: calc(100vh - 235px);overflow: hidden;}
.closing-agent-grid-bg {height: calc(100vh - 510px) !important;}
.uploaded-document {padding: 0;max-height: calc(100vh - 496px);min-height: 300px;}
.uploaded-document .ag-root-wrapper-body{ min-height: 300px;}
.client-info .address.box-border, .client-info .alternate-contact,
.underwriter-info .address.box-border{ margin-top: 15px; margin-bottom: 0;}
.contact-info .closing-agent{ margin-top: 0;}
.order-tile-row .col-lg-2{flex: 0 0 33.3%; max-width: 33.3%; margin-bottom: 10px;}
.order-tile-row .col-lg-2:nth-child(3) {padding-right: 15px;}
.order-tile-row .col-lg-2:nth-child(4) {margin-bottom: 0;}
.order-tile-row .col-lg-2:last-child {padding-right: 0; margin-bottom: 0;}
.dash-inner-container{ height: auto;}
.submit-order-btn { width: 32% !important;}
.dashboard-grid-colum{ margin-top: 15px;}
.dashboard-main-page {height: calc(100vh - 272px); overflow: auto;}
  .dashboard-details-container .ag-root-wrapper-body{ min-height: 200px;}
  .closing-agent-info .contact-information .mat-select {width: 100% !important;}
  .closing-agent-select{ padding-right: 0; line-height: 16px;}
  .horizontal-tab .mat-tab-label{ min-width: inherit !important;}
  .horizontal-tab .mat-tab-body-content label, .horizontal-tab .file-container,
  .horizontal-tab .mat-tab-body-content input{ width: 100%;}
  .horizontal-tab .mat-tab-body-content .profiles-right-btn, .horizontal-tab .mat-tab-body-content .fs-button{ padding-left: 0; width: 100%;}
  app-order-detail .col-lg-4, app-cpl .row.cpl .col-lg-3{-webkit-box-flex: 0;flex: 0 0 100%; max-width: 100%;}
  app-order-detail .col-lg-4 strong, app-order-detail .col-lg-12 strong, .wavs strong, .prewavs strong, .wavs .prewavs .lender-company{ float: left; width: 185px;}
  .wavs span{padding-left: 189px;}
  .prewavs span{padding-left: 189px;}
  /*.row.cpl .mat-form-field-wrapper{ padding-bottom: 15px !important;}*/
  .row.cpl .checkbox-filed{ padding-top: 0 !important;}
  app-order-details .order-tdetails .row .col-lg-9{-webkit-box-flex: 0;flex: 0 0 58.333333%; max-width: 58.333333%;}
  app-order-details .order-tdetails .row .order-details-right{ -webkit-box-flex: 0; flex: 0 0 41.333333%; max-width: 41.333333%;}

  app-wavs .col-lg-6{-webkit-box-flex: 0;flex: 0 0 100%; max-width: 100%;}
  app-pre-wavs .col-lg-6{-webkit-box-flex: 0;flex: 0 0 100%; max-width: 100%;}
  app-client-info .col-lg-4, app-client-info .comomn-fromdesign .col-lg-4, .borrower-form-row .col-lg-4,
  app-property-info .comomn-fromdesign .col-lg-4, app-loan-details .comomn-fromdesign .col-lg-4,
  app-closing-agent-info .comomn-fromdesign .col-lg-4,  app-underwriter .comomn-fromdesign .col-lg-4,
  app-wire-insurance .comomn-fromdesign .col-lg-4{-webkit-box-flex: 0; flex: 0 0 100%; max-width: 100%;}
  app-client-info .lender-info-select{ margin-top: 0;}
  .cpl-date-box button{ right: 2px;}
  .underwriter .field-bg{ margin-bottom: 10px;}
  .extension-bg {padding-left:10px;}
  .extension-bg label {padding-left:0 !important; width:100% !important}
  .extension-bg input { width:100% !important}

}

@media screen and (max-height: 900px) {
  .status-of-order div{ padding: 2px 3px; vertical-align:top;}
  .order-details-right .status-of-order div{ vertical-align:middle;}
  .status-of-order{ font-size: 13px;}
}

@media all and (device-width: 1366px) and (device-height: 768px) and (orientation:landscape) {
  .order-tile-row { padding: 8px 0;}
  .dashboard-main-page {height: calc(100vh - 198px);}
  .dashboard-left-colum .dash-inner-container:last-child{ padding-top: 10px;}
  .dashboard-chart-status{ padding: 2px 15px;}
  .pai-chart-bg {height: calc(100% - 29px);}
  .dashboard-top-bar{ margin-top: 10px;}
  .status-of-order div {padding: 1px 3px;}
  app-admin .orders-page{height: calc(100% - 73px);}
  .order-tdetails {height: calc(100vh - 92px);}
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  .dashboard-main-page {height: calc(100vh - 266px); overflow: auto;}
  .order-table-main {height: calc(100vh - 178px) !important;}
  .closing-agent-grid-bg {height: calc(100vh - 460px) !important;}
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
  .closing-agent-grid-bg { height: calc(100vh - 438px);}
}

@media screen and (max-width: 767px) {
app-order-details .order-tdetails .row .col-lg-9{width: 100%;flex: 0 0 100%;max-width: 100%;}
app-order-details .order-tdetails .row .order-details-right{width: 100%;flex: 0 0 100%;max-width: 100%;}
}


@media screen and (max-width: 479px) {
  app-order-detail strong, app-wavs strong{ width: 100% !important; clear: both; height: auto !important;}
  .box-container-main .message, .wavs span{ padding-left: 0 !important;}
}

@media screen and (max-width: 479px) {
  app-order-detail strong, app-pre-wavs strong { width: 100% !important; clear: both; height: auto !important;}
  .box-container-main .message, .prewavs span { padding-left: 0 !important;}
}

.cpl_datepicker button {
  right: 38px;
  top: -5px;
}

/*------ style start for ng select search and disable property -----*/
[ng-reflect-disabled="true"] .ngx-select__toggle {
  color: rgba(0,0, 0, 0.38) !important;
}
.ngx-select__choices{top:29px;}
/*------ style END for ng select search and disable property -----*/

/*----- style for 1174 ----*/
.tree-view-profile {padding-top:15px}
  .tree-view-profile .company-list {
    background: #2857a9;
    color: #fff;
    padding: 1px 10px 5px 10px;
    font-weight: 600;
    font-size: 16px;
    height:30px;
    margin-top: 2px;
  }

.treeview-container {
  padding-right: 0 !important;
  margin-top: 5px;
  max-height:inherit !important; height:calc(100vh - 242px)
}

  .treeview-container .form-inline {
    background: #039be5;
    color: #fff;
    padding: 4px 10px;
    margin-bottom: 0px !important;
    border-bottom: solid 1px #eae4e4;
  }

    .treeview-container .form-inline .form-check-label {
      color: #fff;
    }

  .treeview-container .treeview-item {
    border: solid 1px #ddd; border-top:none;
  }
  .treeview-container ngx-treeview-item ngx-treeview-item .treeview-item {
    margin-left: 20px !important; border:none !important;
  }

  .treeview-container ngx-treeview-item ngx-treeview-item .form-inline {
    background: #fff;
    color: #444; border-bottom:none;
  }

    .treeview-container ngx-treeview-item ngx-treeview-item .form-inline .form-check-label {
      color: #444
    }
ngx-treeview-item input[type=checkbox] {
  display: none !important;
}
  ngx-treeview-item input[type=checkbox] [ng-reflect-model="true"] {
    color: red !important;
  }

.treeview-header {
  display: none !important;
}

/*---- style for dashboard changes --*/

@media (min-width:1025px) {
  .dashboard-main-page .col-lg-4.dashboard-left {width: 380px !important; flex:inherit !important;}
  .dashboard-main-page .dsahboard-right {width: calc(100% - 380px) !important; flex: inherit !important;
    max-width: inherit;}
  .transaction-left-top {width: 275px !important}
  .verticle-tab .mat-tab-header {width:calc(268px - 8px);}
  .verticle-tab .mat-tab-body-wrapper {
    margin-left: calc(268px + 21px) !important;
    width: calc(100% - 268px - 21px) !important;
  }
  /*.order-detail-right .bottom-menu-bg {padding-left: calc(268px + 21px);}*/
  .custom-large-btn {letter-spacing:-0.5px; padding: 0 10px !important;}
}
.order-detail-right .bottom-menu-bg {
  position: absolute;
  right: 0;
  top: 1px;
  width: auto;
  padding: 0;
  z-index: 9;
  width: auto;
  bottom: inherit;
  min-height: 31px;
  background: #2857a9;
  margin-right: 6px;
}
.order-detail-right .bottom-menu-bg .button-bg {border:none; padding:0;}
  .order-detail-right .bottom-menu-bg .button-bg .fs-button {
    background: #039be5 !important;
    height: 23px !important;
    margin-top: 4px;
  }

/* style start for news and info icon*/
.custom-tooltip {margin:9px 5px !important}
.custom-tooltip::before, .custom-tooltip::after {border: 0 !important;}
/* style close for news and info icon*/

@media only screen and (max-width: 1090px) and (min-width:992px) {
  .horizontal-tab .mat-tab-body-content label {
    min-width: 120px;
  }

  .horizontal-tab .mat-tab-body-content input {
    width: calc(100% - 120px); padding:0 4px;
  }

  .horizontal-tab .mat-tab-body-content .profiles-right-btn {
    padding-left: 120px
  }
}

.uploadDoc .filename {
  width: 450px;
  font-size: 12px;
  white-space: nowrap;
}
.properyinfo .mat-form-field-subscript-wrapper {
  padding: 2px 0 0 160px !important;
  top: 26px;
  margin: 0;
}

.addNewcl .ngx-select__choices {
  max-height: 140px !important;
}


.addNewcl .ng-select__choices {
  max-height: 140px !important;
}

.ngx-custom-select .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 130px !important;
}
/*
@media screen and (max-width: 1270px) {
  .order-detail-right .mat-form-field-subscript-wrapper {
  }
  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    padding-left: 0px !important;
    top: 51px;
  }
}*/
.ng-select .ng-select-container .ng-value-container .ng-input > input {
  width: 100% !important;
}

/* ------ style for ngx-custom-select -----------*/
.ngx-custom-select .ng-input > input {
  padding: 0 !important;
}
.ngx-custom-select .ng-select-container{height:30px !important}
.ngx-custom-select .ng-select .ng-select-container{min-height:30px !important; border-radius:0 !important;top:-1px;}
/*.ngx-custom-select .ng-value-container {
  padding-top: 7px !important;
}
.ngx-custom-select .ng-clear-wrapper, .ngx-custom-select .ng-arrow-wrapper{padding-top:8px !important;}
  */
.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: -4px !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  padding-top: 0px !important;
  height: 30px;
  line-height: 30px;
  display: block;
  position: relative;
  top: 0px;
}
.ng-clear-wrapper .ng-clear {
  position: relative;
  top: 2px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
top:0px !important;
}

/*ng-select:disabled .ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  color: rgba(0, 0, 0, 0.38);
}*/

.propertyInfoAddMode .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  color: rgba(0, 0, 0, 0.38) !important;
}
.propertyInfoUpdateMode .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  color: #000 !important;
}

.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
 
  margin-left: 5px;

}


.loaninfo_calander .mat-datepicker-popup {
  left: 500px !important;
  margin-top: 5px !important;
  border: solid 1px #c00;
}

.lonadatepicker {
  border: solid 1px #c00;
}

.satdatepicker .mat-datepicker-popup {
  left: 500px !important;
  margin-top: 5px !important;
  border: solid 1px #c00;
}

.FScheckboxLabel {
  width: auto;
  padding: 0;
  margin: 0;
  display: inline;
}

.FSchkboxCustom {
  display: inline-block;
  filter: alpha(opacity=0);
  position: relative;
  height: 1.25rem;
  width: 1.25rem !important;
  z-index: 1;
  vertical-align: bottom;
  border: .125rem solid #0B6EFD;
  background-color: #0B6EFD;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 1px;
}
  .FSchkboxCustom:hover {
    background-color: #0B6EFD !important;
  }
.FSchkboxCustom:focus {
    outline: #717171 dashed .0625rem;
    outline-offset: 0px;
}
.loanTab .mat-form-field-appearance-outline .mat-form-field-flex {
  margin: 0px !important;
}

/* /borrower popup css */
.borrowerGridActionIcon i{font-size: 18px;}
.borrowerGridActionIcon .clear-icon{font-size: 20px;}
.additionalBorrowersGrid .borrowerGridActionIcon {
  width: 28px;
  border: none;
  background: transparent;
}
.additionalBorrowersGrid .borrowerGridActionIcon .clear-icon {
  font-size: 24px;
  color: red;
}
  .additionalBorrowersGrid .borrowerGridActionIcon .edit-icon {
    font-size: 22px;
    color: #039be5;
  }

.dashboardGridLink:hover {
  cursor: pointer;
  line-height: 16px;
  text-decoration: none;
  border-bottom: solid 1px #0056b3;
}

/*Global notes grid css*/
.notesGridContainer {
  height: calc(100vh - 462px);
}
.notesSection {
  margin: 20px 0;
}
.notexTxtareaDiv {
  /*margin-top: 20px;*/
  margin-bottom: 0px !important;
}
.notes-textarea {
  padding: 15px 15px 10px 15px;
  width: 87%;
  display: inline-block;
}
  .notes-textarea textarea {
    height: 70px;
    min-height: 70px;
    border: solid 1px #ddd;
    padding: 5px 10px !important;
  }
.notes-bg .add-notes {
  width: 10%;
  display: inline-block;
  height: 86px;
  vertical-align: super;
}
  .notes-bg .add-notes button {
    width: 100px;
  }
.notes-bg .add-notes button:last-child {
  margin-top: 6px;
}

.mat-expansion-indicator {
  position: absolute !important;
}

.notesAccordian .mat-expansion-panel-header-title {
  margin-left: 26px !important;
  color: #fff !important;
}

.notesAccordian .mat-expansion-indicator::after {
  height: 20px;
  width: 20px;
  -webkit-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
  border: 0 !important;
  content: '+' !important;
  font-size: 30px;
  line-height: 12px;
  text-align: center;
  display: inherit !important;
  color: #fff;
}
.notesAccordian .mat-expansion-panel-header {
  cursor: pointer;
  background: #039be5 !important;
  color: #fff;
  font-size: 16px;
  padding: 0 12px !important;
  font-weight: 600;
  width: 100%;
  height: 32px !important;
}
.notesAccordian .mat-expansion-panel-body {
  padding-top: 20px !important;
}
.notesAccordian .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.notesAccordian .mat-expansion-panel:first-of-type {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}
.notesAccordian .mat-expanded .mat-expansion-indicator::after {
  content: '-' !important;
  font-size: 30px;
  line-height: 10px;
}

.notesAccordian .mat-expansion-panel {
  box-shadow: none !important;
  border-left: solid 0px !important;
  border-right: solid 0px !important;
}
.notesAccordian ag-grid-angular {
  height: 400px;
}
.notes_Common {
  margin-top: 12px;
}

/*End: Global notes grid css*/


.timeOutDialog mat-dialog-actions {
  justify-content: center;
  margin: 0px !important;
}

.wireInfoRadio .fa-radio {
  border: .125rem solid #ff4081;
  background-color: #ff4081 !important;
  vertical-align: initial;
}
.file-btn {
  opacity: 1;
}
.file-btn:focus {
    opacity: 0.5;
  }

.fs-filetype ngx-mat-file-input:focus-within {
  box-shadow: -1px 0px 3px 1px #007eff;
 
}

/*datepicker custom css form dashboard/order*/
.mat-date-range-input-container {
  display: none !important;
}

.datePickClassDash .mat-option-text, .datePickClass .mat-option-text {
  text-indent: 100px !important;
}
.datePickClassDash .mat-datepicker-toggle button,.datePickClass .mat-datepicker-toggle button {
  width: 100% !important;
  display: block !important;
  position: absolute !important;
  right: 0px;
  text-align: right;
  padding-right: 15px;
  border-radius: 0px;
}
.checkbox-filedSendEmail .mat-checkbox-inner-container {
  height: 16px;
  width: 16px;
}
.checkbox-filedSendEmail .mat-checkbox-layout {
  width: auto !important;
  padding: 0;
  margin: 0;
}

.contactGridActionIcon i {
  font-size: 18px;
}

.contactGridActionIcon .clear-icon {
  font-size: 20px;
}

.closingAgentContactGrid .contactGridActionIcon {
  width: 28px;
  border: none;
  background: transparent;
}

  .closingAgentContactGrid .contactGridActionIcon .clear-icon {
    font-size: 24px;
    color: red;
  }

  .closingAgentContactGrid .contactGridActionIcon .edit-icon {
    font-size: 22px;
    color: #039be5;
  }



@media screen and (max-width: 1279px) {
  .order-table-main {
    margin-top: 15px;
  }
}

ngx-ui-loader {
  pointer-events: none;
}

.ngx-overlay
{
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 99999 !important;
  pointer-events: auto !important; 
}

.disabled-button{
   height: 30px !important;
    border-radius: 0 !important;
    line-height: 10px !important;
    background: #8fa2ab !important;
    cursor: pointer !important;
    color: #fff !important;
}